import React, { Suspense } from "react";
import "./App.scss";
import { HashRouter, Routes, Route } from "react-router-dom";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Sidebar from "./Components/Sidebar";

import routes from "./routes";

function App() {
  return (
    <HashRouter>
      <Header />
      <main>
	    <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route key={index} path={route.path} name={route.name} element={<route.element />} />
            )
          })}
        </Routes>
        </Suspense>
      </main>
      <Footer />
      <Sidebar />
    </HashRouter>
  );
}

export default App;
