import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import welcomeImgw from "./../Images/welcome-img.webp";
import welcomeImg from "./../Images/welcome-img.jpg";

import brandImg1w from "./../Images/icat-brand.webp";
import brandImg1 from "./../Images/icat-brand.jpg";
import brandLogo1 from "./../Images/icat.png";
import brandImg2w from "./../Images/image-brand.webp";
import brandImg2 from "./../Images/image-brand.jpg";
import brandLogo2 from "./../Images/image.png";
import brandImg3w from "./../Images/iminds-brand.webp";
import brandImg3 from "./../Images/iminds-brand.jpg";
import brandLogo3 from "./../Images/iminds.png";

import mileImg1w from "./../Images/mile1.webp";
import mileImg1 from "./../Images/mile1.png";
import mileImg2w from "./../Images/mile2.webp";
import mileImg2 from "./../Images/mile2.png";
import mileImg3w from "./../Images/mile3.webp";
import mileImg3 from "./../Images/mile3.png";
import mileImg4w from "./../Images/mile4.webp";
import mileImg4 from "./../Images/mile4.png";
import mileImg5w from "./../Images/mile5.webp";
import mileImg5 from "./../Images/mile5.png";
import mileImg6w from "./../Images/mile6.webp";
import mileImg6 from "./../Images/mile6.png";
import mileImg7w from "./../Images/mile7.webp";
import mileImg7 from "./../Images/mile7.png";

import CEOw from "./../Images/ceo.webp";
import CEO from "./../Images/ceo.jpg";

import octImg1w from "./../Images/oct1.webp";
import octImg1 from "./../Images/oct1.jpg";
import octImg2w from "./../Images/oct2.webp";
import octImg2 from "./../Images/oct2.jpg";
import octImg3w from "./../Images/oct3.webp";
import octImg3 from "./../Images/oct3.jpg";
import octImg4w from "./../Images/oct4.webp";
import octImg4 from "./../Images/oct4.jpg";
import octImg5w from "./../Images/oct5.webp";
import octImg5 from "./../Images/oct5.jpg";
import octImg6w from "./../Images/oct6.webp";
import octImg6 from "./../Images/oct6.jpg";

import evntImg1w from "./../Images/event1.webp";
import evntImg1 from "./../Images/event1.jpg";
import evntImg2w from "./../Images/event2.webp";
import evntImg2 from "./../Images/event2.jpg";
import evntImg3w from "./../Images/event3.webp";
import evntImg3 from "./../Images/event3.jpg";
import evntImg4w from "./../Images/event4.webp";
import evntImg4 from "./../Images/event4.jpg";

const brandItems = [
    { name: 'ICAT', to: '/icat', srset1: brandImg1w, srset2: brandImg1, brandLogo: brandLogo1, brandWidth: '92', brandHt: '50', info1: '03', label1: 'Campuses', info2: '9K+', label2: 'Graduates' },
    { name: 'IMAGE', to: '/image-creative-education', srset1: brandImg2w, srset2: brandImg2, brandLogo: brandLogo2, brandWidth: '87.7', brandHt: '50', info1: '30', label1: 'Centers', info2: '78K+', label2: 'Students' },
    { name: 'Imageminds', to: '/imageminds', srset1: brandImg3w, srset2: brandImg3, brandLogo: brandLogo3, brandWidth: '89', brandHt: '50', info1: '19', label1: 'Schools', info2: '2.2L', label2: 'Students' },

]
const featItems = [
    {
        ftIcon: 'flag',
        ftTitle: 'Enriching Nearly 3 Lakh Students',
        ftCont: 'With comprehensive educational programs and resources, the Image Group has empowered students of different age groups.',
    },
    {
        ftIcon: 'target',
        ftTitle: 'Career Path for a Lakh of Students',
        ftCont: 'Quality education, along with the best placement services, has illuminated the career path for around a lakh students.',
    },
    {
        ftIcon: 'crown',
        ftTitle: 'Earning Accreditations',
        ftCont: 'Coordination with reputable national and international institutions, universities, and government bodies like the NSDC to provide a world-class curriculum.',
    },
    {
        ftIcon: 'graph',
        ftTitle: 'Finest Journey of 3 Decades',
        ftCont: 'In the last 28+ years, Image Group has upgraded itself to keep up with the dynamic trends of the creative industry and still remains relevant.',
    },
    {
        ftIcon: 'building',
        ftTitle: 'Industry-Relevant Curriculum',
        ftCont: 'The Image Group continuously updates its curriculum to ensure that students are equipped with the latest industry-relevant skills and knowledge.',
    },
    {
        ftIcon: 'globe',
        ftTitle: 'Best-In-Class Infrastructure',
        ftCont: "Students have access to cutting-edge infrastructure, necessary technology, and equipment at the Image Group's establishments.",
    },
]
const octItems = [
    {
        srset1: octImg1w,
        srset2: octImg1,
    }, {
        srset1: octImg2w,
        srset2: octImg2,
    }, {
        srset1: octImg3w,
        srset2: octImg3,
    }, {
        srset1: octImg4w,
        srset2: octImg4,
    }, {
        srset1: octImg5w,
        srset2: octImg5,
    }, {
        srset1: octImg6w,
        srset2: octImg6,
    },
]
const evntItems = [
    {
        title: "Launching Creative Technologies With Gen AI Chennai - 2024",
        srset1: evntImg1w,
        srset2: evntImg1,
        to: "/launching-creative-technologies-with-gen-ai-chennai-2024",
    },
    {
        title: "ஓவியக் கலையை வளர்ப்பதற்கான முதல் முயற்சியாக நடத்தப்பட்ட 'இமேஜ் ஆர்ட் சேலஞ்ச் 2024'",
        srset1: evntImg2w,
        srset2: evntImg2,
        to: "/iac-prize-distribution-ceremony-chennai-2024",
    },
    {
        title: 'My India My Pride - Photography Contest Winners and Runner prize distribution',
        srset1: evntImg3w,
        srset2: evntImg3,
        to: "/photography-contest-winners-runner-prize-distribution",
    },
    {
        title: "Comfusionism - Miraki 2023 - Inter Collegiate Ciltural Fest, Chennai",
        srset1: evntImg4w,
        srset2: evntImg4,
        to: "/comfusionism-miraki-2023-chennai",
    },
]
const timeItems1 = [
    {
        tTitle: "Established IMAGE CREATIVE EDUCATION",
        tClass: "milestone1",
        tCont: "Established India's first professional Multimedia Training Institute",
        tYear: "1996",
        srset1: mileImg1w,
        srset2: mileImg1,
    },
    {
        tTitle: "Ramayan The Interactive Epic",
        tClass: "milestone3",
        tCont: "Released Interactive Animated Storybook on CD ROM",
        tYear: "1999",
        srset1: mileImg3w,
        srset2: mileImg3,
    },
    {
        tTitle: "Launched IMAGEMINDS",
        tClass: "milestone5",
        tCont: "Launched IMAGEMINDS - creative education for school children",
        tYear: "2011",
        srset1: mileImg5w,
        srset2: mileImg5,
    },
    {
        tTitle: "Won EDUTECH ASIA AWARDS",
        tClass: "milestone7",
        tCont: "Won the prideful 'Most Inclusive Educational Programme - Gold' award",
        tYear: "2019",
        srset1: mileImg7w,
        srset2: mileImg7,
    },
]
const timeItems2 = [
    {
        tTitle: "Developed VIRUTAL 3D CRICKET",
        tClass: "milestone2",
        tCont: "Developed real-time, virtual 3D Cricket Simulation game & Web Games for 'Sify.com'",
        tYear: "1998",
        srset1: mileImg2w,
        srset2: mileImg2,
    },

    {
        tTitle: "Launched ICAT",
        tClass: "milestone4",
        tCont: "Launched India's first Digital Media College at Chennai",
        tYear: "2004",
        srset1: mileImg4w,
        srset2: mileImg4,
    },

    {
        tTitle: "Launched IALS",
        tClass: "milestone6",
        tCont: "Launched IALS (Image Advanced Learning Systems) training modules for iPads",
        tYear: "2012",
        srset1: mileImg6w,
        srset2: mileImg6,
    },
]

AOS.init({
    duration: 1200
});


export default function Home() {

    useEffect(() => {
        const handleResize = () => {
            setTimeout(() => {
                var i = 0;
                var set = 0;
                var cols = document.querySelectorAll("[data-col]");
                var encountered = [];
                for (i = 0; i < cols.length; i++) {
                    var attr = cols[i].getAttribute("data-col");
                    if (encountered.indexOf(attr) === -1) {
                        encountered.push(attr);
                    }
                }
                for (set = 0; set < encountered.length; set++) {
                    var col = document.querySelectorAll(
                        '[data-col="' + encountered[set] + '"]'
                    );
                    var group = [];
                    for (i = 0; i < col.length; i++) {
                        col[i].style.height = "auto";
                        var elementHeight = col[i].offsetHeight;
                        group.push(elementHeight);
                    }
                    for (i = 0; i < col.length; i++) {
                        col[i].style.height = "auto";
                        col[i].style.height = Math.max.apply(Math, group) + "px";
                    }
                }
            }, 1000);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
    }, []);

    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: false,
    };

    var settings2 = {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1081,
                settings: {
                    slidesToShow: 3,
                    arrows: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    arrows: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                },
            },
        ],
    };

    const [expandedIndex, setExpandedIndex] = useState(0);
    return (
        <div className='content'>
            <div className='banner'>
                <Slider className="banner-slider" {...settings}>
                    <div className='banner-cont banner-img1'></div>
                </Slider>
            </div>
            <div className='brands-cont fix'><div className='container-fluid sectin'>
                <h2 className='h4 fw-600 mb-4 txt-center'>What we do?</h2>
                <p className='txt-center mxw-900 m-auto mb-4'>Through many of its establishments, IMAGE Group provides creative education to people of all age groups, making it the sole organization of its kind in India and perhaps the world. This involves providing school students (as early as five years old) with solid foundational skills in design and creative technology, as well as offering innovative full-time degrees and creative programmes for youngsters pursuing a creative career path and adults who are interested in making a quick career switch or moving up the corporate ladder.</p>
                <div className='brand-cont row g-lg-5' data-aos="fade-up" data-aos-once='true'>
                    {brandItems.map((item, index) => {
                        return (
                            <Link to={item.to} key={index} className='col-md-5 col-15 brand-item'>
                                <div className='brand-logo txt-center'><img src={item.brandLogo} alt={item.name} title={item.name} width={item.brandWidth} height={item.brandHt} className='m-auto' /></div>
                                <div className='brand-img'><picture>
                                    <source srcSet={item.srset1} type="image/webp" />
                                    <source srcSet={item.srset2} type="image/jpeg" />
                                    <img
                                        src={item.srset2}
                                        alt={item.name}
                                        title={item.name}
                                        width="750"
                                        height="550"
                                    />
                                </picture></div>
                                <div className='brand-info'>
                                    <ul className='float-none'>
                                        <li className='float-start'><div className='f-18 txt-thm-clr-3 fw-600'>{item.info1}</div><label className='d-blk f-12'>{item.label1}</label></li>
                                        <li className='float-start'><div className='f-18 txt-thm-clr-3 fw-600'>{item.info2}</div><label className='d-blk f-12'>{item.label2}</label></li>
                                    </ul>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div></div>
            <div className='sectin fix'>
                <div className='container-fluid sectin pt-0'>
                    <h6 className='f-16 txt-thm-clr-3 fw-600 txt-center'>Evolution of Image Group</h6>
                    <h2 className='h4 fw-600 mb-0 txt-center'>Our Journey to Success</h2>
                </div>
                <div className='timeline-cont'>
                    <div className='timeline-inner-cont float-none'>
                        <Link to='/milestones' className='timeline'>
                            <ul className='time-list1'>
                                {timeItems1.map((item, index) => {
                                    return (
                                        <li key={index} className={item.tClass}>
                                            <h5 className='f-oswald f-20 fw-400 txt-thm-clr-3'>{item.tTitle}</h5>
                                            <p>{item.tCont}</p>
                                            <div className='mile-year h8 txt-thm-clr-3 fw-500'>{item.tYear}</div>
                                            <div className='mile-img'><picture>
                                                <source srcSet={item.srset1} type="image/webp" />
                                                <source srcSet={item.srset2} type="image/jpeg" />
                                                <img
                                                    src={item.srset2}
                                                    alt={item.tTitle}
                                                    title={item.tTitle}
                                                    width="160"
                                                    height="145"
                                                />
                                            </picture></div>
                                            <div className='clear'></div>
                                            <div className='mile-pointer'></div>
                                        </li>
                                    )
                                })}
                            </ul>
                            <ul className='time-list2'>
                                {timeItems2.map((item, index) => {
                                    return (
                                        <li key={index} className={item.tClass}>
                                            <div className='mile-pointer'></div>
                                            <div className='mile-year h8 txt-thm-clr-3 fw-500'>{item.tYear}</div>
                                            <div className='mile-img'><picture>
                                                <source srcSet={item.srset1} type="image/webp" />
                                                <source srcSet={item.srset2} type="image/jpeg" />
                                                <img
                                                    src={item.srset2}
                                                    alt={item.tTitle}
                                                    title={item.tTitle}
                                                    width="160"
                                                    height="145"
                                                />
                                            </picture></div>
                                            <div className='clear'></div>
                                            <h5 className='f-oswald f-20 fw-400 txt-thm-clr-3'>{item.tTitle}</h5>
                                            <p>{item.tCont}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='welcome-cont fix'><div className='container-fluid sectin'>
                <div className='row g-lg-3'>
                    <div className='col-lg-8 col-15 wel-img mxw-700' data-aos="fade-right" data-aos-once='true'>
                        <picture>
                            <source srcSet={welcomeImgw} type="image/webp" />
                            <source srcSet={welcomeImg} type="image/png" />
                            <img
                                src={welcomeImg}
                                alt="Welcome to IMAGE"
                                title="Welcome to IMAGE"
                                width="700"
                                height="580"
                            />
                        </picture></div>
                    <div className='col-lg-8 col-15 wel-cont mxw-700' data-aos="fade-left" data-aos-once='true'>
                        <h6 className='f-16 txt-thm-clr-3 fw-600 txt-lg-lt txt-center'>About us</h6>
                        <h2 className='h4 fw-600 mb-4 txt-lg-lt txt-center'>Welcome to IMAGE</h2>
                        <p className='f-16 mb-2 txt-lg-lt txt-center'>IMAGE Group is a pioneer in offering education in various creative streams in India. It was established in 1995, since when it taps every opportunity to offer best creative education to varied audience.</p>
                        <p className='f-16 mb-2 txt-lg-lt txt-center'>Its early vision towards the need for proper education and skill training in Art, Design and Media streams enabled it to establish India's first-of-its-kind College to offer regular, full-time Graduation and Post-Graduation courses; and the India's first professionally managed skill training institute very early when not many in the industry even thought of such an education.</p>
                        <p className='f-16 txt-lg-lt txt-center'>Also, IMAGE Group, with a goal to build a stronger nation, has also pitched in to nurture creativity and innovation as a practice in young children by introducing them to creative education. Along with various training services, IMAGE also provides services related to digital media content development. </p>
                    </div>
                </div>
            </div></div>
            <div className='features-cont'><div className='container-fluid sectin'>
                <div className='row g-lg-3'>
                    <div className='col-xl-8 col-15 txt-xl-lt txt-center feat-txt' data-col="js-auto-height">
                        <h6 className='f-16 txt-thm-clr-3 fw-600 txt-xl-lt txt-center'>Our Achievements</h6>
                        <h2 className='h4 fw-600 mb-4 txt-xl-lt txt-center'>Providing Finest Creative Education Services</h2>
                        <p className='f-16 mxw-700 m-xl-0 m-auto'>Image Group has expanded over the last three decades to become India's leading creative education provider, delivering the best quality creative education services. We aspire to equip individuals to attain their greatest potential in their chosen creative fields by supporting innovation, critical thinking, and artistic expression.</p>
                    </div>
                    {featItems.map((item, index) => {
                        return (
                            <div key={index} className='col-xl-3 col-md-5 col-sm-8 col-15 feat-item' data-col="js-auto-height" data-aos="zoom-in" data-aos-once='true'>
                                <div className={`feat-icon ${item.ftIcon}`}></div>
                                <h6 className='f-20 fw-600 txt-black mb-2'>{item.ftTitle}</h6>
                                <p>{item.ftCont}</p>
                            </div>
                        )
                    })}
                </div>
            </div></div>
            <div className='ceo-cont fix'><div className='container-fluid sectin'>
                <div className='row g-lg-3'>
                    <div className='col-lg-8 col-15 mxw-700 ceo-lt' data-aos="fade-right" data-aos-once='true'>
                        <h6 className='f-16 txt-thm-clr-3 fw-600 txt-lg-lt txt-center'>About The Founder & CEO</h6>
                        <h2 className='h4 fw-600 mb-4 txt-lg-lt txt-center'>Leader & Pioneer in Creative Education</h2>
                        <p className='f-16 mxw-700 m-auto mb-3 txt-lg-lt txt-center'>The Founder & CEO of IMAGE Group is the pioneering orchestrator of Indian Digital and Media Education. The visionary built his kingdom of creative education as the only organization in the world to serve such a vast range of creative education needs of varied audience.</p>
                        <div className='wdth-80p mxw-450 m-lg-0 m-auto f-16 txt-black float-none'>
                            <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28 22.7152H17.245C16.1943 19.3157 15.6689 15.7925 15.6689 12.1457C15.6689 8.43709 16.6269 5.5011 18.543 3.33774C20.521 1.11258 23.426 0 27.2583 0V5.19205C24.1678 5.19205 22.6225 7.07726 22.6225 10.8477V12.6093H28V22.7152ZM12.3311 22.7152H1.57616C0.525386 19.3157 0 15.7925 0 12.1457C0 8.43709 0.958057 5.5011 2.87417 3.33774C4.8521 1.11258 7.75717 0 11.5894 0V5.19205C8.4989 5.19205 6.95364 7.07726 6.95364 10.8477V12.6093H12.3311V22.7152Z" fill="#B42E91" /></svg>
                            <span className='d-blk'>People now recognise that having a good performance conversation means that something happens as a result.</span>
                            <svg className='float-end' width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.90735e-06 0.000101089H10.755C11.8057 3.39966 12.3311 6.92284 12.3311 10.5696C12.3311 14.2782 11.3731 17.2142 9.45695 19.3776C7.47903 21.6028 4.57395 22.7153 0.741724 22.7153V17.5233C3.83223 17.5233 5.37749 15.6381 5.37749 11.8677V10.1061H1.90735e-06V0.000101089ZM15.6689 0.000101089H26.4238C27.4746 3.39966 28 6.92284 28 10.5696C28 14.2782 27.0419 17.2142 25.1258 19.3776C23.1479 21.6028 20.2428 22.7153 16.4106 22.7153V17.5233C19.5011 17.5233 21.0464 15.6381 21.0464 11.8677V10.1061H15.6689V0.000101089Z" fill="#B42E91" /></svg>
                        </div>
                        <div className='txt-lg-lt txt-center'><Link to="/about-the-Founder-CEO" className='btn m-lg-0 m-auto mb-5'>Read Success Story</Link></div>
                    </div>
                    <div className='col-lg-8 col-15 mxw-700 txt-center ceo-rt mb-0'>
                        <div className='ceo-ct wdth-80p mxw-500 m-auto' data-aos="fade-left" data-aos-once='true'>
                            <div>
                                <div className='ceo-img'>
                                    <picture>
                                        <source srcSet={CEOw} type="image/webp" />
                                        <source srcSet={CEO} type="image/png" />
                                        <img src={CEO} alt="Mr.K.Kumar - The Founder & CEO, IMAGE Group of Companies" title="Mr.K.Kumar - The Founder & CEO, IMAGE Group of Companies" width='672' height='1291' />
                                    </picture></div>
                                <div className='ceo-dts'>
                                    <h6 className='txt-white'>Mr. K. Kumar</h6>
                                    <p className='txt-white fw-600'>The Founder & CEO, Image Group of Companies</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>
            <div className='occasion-cont'>
                <div className='container-fluid sectin'>
                    <h6 className='f-16 txt-thm-clr-3 fw-600 txt-center'>Top Events</h6>
                    <h2 className='h4 fw-600 mb-4 txt-center'>Our Proud Occasions</h2>
                    <div className='oct-slider'>
                        <div className='oct-slider-cont mb-6'>
                            <table width='100%' cellPadding='0' cellSpacing='0' border='0'>
                                <tbody><tr>
                                    {octItems.map((item, index) => {
                                        return (
                                            <td key={index} className={`accordion-row ${expandedIndex === index ? "expanded" : ""}`} onMouseEnter={() => setExpandedIndex(index)} onMouseLeave={() => setExpandedIndex(0)}>
                                                <div><picture>
                                                    <source srcSet={item.srset1} type="image/webp" />
                                                    <source srcSet={item.srset2} type="image/jpeg" />
                                                    <img
                                                        src={item.srset2}
                                                        alt='Our Proud Occasions'
                                                        title='Our Proud Occasions'
                                                        width="900"
                                                        height="650"
                                                    />
                                                </picture></div>
                                            </td>
                                        )
                                    })}</tr></tbody></table></div>
                    </div>
                </div>
            </div>
            <div className='news-cont'>
                <div className='container-fluid sectin'>
                    <h6 className='f-16 txt-thm-clr-3 fw-600 txt-center'>News & Events</h6>
                    <h2 className='h4 fw-600 mb-4 txt-center'>What's On the Bulletin?</h2>
                    <p className='txt-center mxw-700 m-auto'>IMAGE provides various opportunities for its students to experiment, explore and exhibit their skills. Find out such latest opportunities and accolades of IMAGE Group.</p>
                    <div className='evnt-slider-cont' data-aos="fade-up" data-aos-once='true'>
                        <Slider className="evnt-slider" {...settings2}>
                            {evntItems.map((item, index) => {
                                return (
                                    <Link key={index} to={item.to}>
                                        <div className="event-img">
                                            <picture>
                                                <source srcSet={item.srset1} type="image/webp" />
                                                <source srcSet={item.srset2} type="image/jpeg" />
                                                <img
                                                    src={item.srset2}
                                                    alt={item.title}
                                                    title={item.title}
                                                    width="500"
                                                    height="3333"
                                                />
                                            </picture>
                                        </div>
                                        <div className='event-cont txt-center' data-col="js-auto-height2">
                                            <h6 className='f-16 txt-center fw-600'>{item.title}</h6>
                                            <div className='view-more-btn'>Read More</div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}
