import React from "react";

import Home from "./Pages/Home";

const ImageCreativeEducation = React.lazy(() => import("./Pages/creative-solutions/image-creative-education"));
const Icat = React.lazy(() => import("./Pages/creative-solutions/icat"));
const Imageminds = React.lazy(() => import("./Pages/creative-solutions/imageminds"));

const AboutTheFounderCEO = React.lazy(() => import("./Pages/About/about-the-Founder-CEO"));
const milestones = React.lazy(() => import("./Pages/About/milestones"));

const NewsEvents = React.lazy(() => import("./Pages/NewsEvents/news-events"));
const LaunchingCreativeTechnologiesWithGenAIChennai2024 = React.lazy(() => import("./Pages/NewsEvents/launching-creative-technologies-with-gen-ai-chennai-2024"));
const IacPrizeDistributionCeremonyChennai2024 = React.lazy(() => import("./Pages/NewsEvents/iac-prize-distribution-ceremony-chennai-2024"));
const PhotographyContestWinnersRunnerPrizeDistribution = React.lazy(() => import("./Pages/NewsEvents/photography-contest-winners-runner-prize-distribution"));
const ComfusionismMiraki2023Chennai = React.lazy(() => import("./Pages/NewsEvents/comfusionism-miraki-2023-chennai"));
const AVGCStakeholderConsultationWorkshop = React.lazy(() => import("./Pages/NewsEvents/AVGC-stakeholder-consultation-workshop"));
const RampcultureImageFashionDeptStudentsParticipatedAvantGardeFashionShow2023 = React.lazy(() => import("./Pages/NewsEvents/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023"));
const InterCollegiateCulturalFestMiraki2023 = React.lazy(() => import("./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2023-chennai"));
const FifteenthGraduationShowcaseOfICATDesignMediaCollegeChennai = React.lazy(() => import("./Pages/NewsEvents/15th-graduate-showcase-2023-chennai"));
const ThirteenthGraduationShowcaseOfICATDesignMediaCollegeBangalore = React.lazy(() => import("./Pages/NewsEvents/13th-graduate-showcase-2023-bangalore"));
const TwelvethGraduationShowcaseOfICATDesignMediaCollegeHyderabad = React.lazy(() => import("./Pages/NewsEvents/12th-graduate-showcase-2023-hyderabad"));
const FourteenthGraduationShowcaseOfICATDesignMediaCollegeChennai = React.lazy(() => import("./Pages/NewsEvents/14th-graduate-showcase-2022-chennai"));
const TwelvethGraduationShowcaseOfICATDesignMediaCollegeBangalore = React.lazy(() => import("./Pages/NewsEvents/12th-graduate-showcase-2022-bangalore"));
const StudentShowcaseOfICATDesignMediaCollegeChennai = React.lazy(() => import("./Pages/NewsEvents/student-showcase-2021-chennai"));
const EleventhBCUConvocation2020 = React.lazy(() => import("./Pages/NewsEvents/11th-bcu-convocation-ceremony-2020"));
const InterCollegiateCulturalFestMiraki2020Bangalore = React.lazy(() => import("./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2020-bangalore"));
const InterCollegiateCulturalFestMiraki2020Chennai = React.lazy(() => import("./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2020-chennai"));
const NinethGraduationShowcaseOfICATDesignMediaCollegeBangalore = React.lazy(() => import("./Pages/NewsEvents/9th-graduate-showcase-2019-bangalore"));
const NinethGraduationShowcaseOfICATDesignMediaCollegeHyderabad = React.lazy(() => import("./Pages/NewsEvents/9th-graduate-showcase-2019-hyderabad"));
const EleventhGraduationShowcaseOfICATDesignMediaCollegeChennai = React.lazy(() => import("./Pages/NewsEvents/11th-graduate-showcase-2019-chennai"));
const IMAGEMINDSWonTheMostInclusiveEducationalProgrammeGoldAward = React.lazy(() => import("./Pages/NewsEvents/won-most-inclusive-educational-programme-gold-award"));
const RajaRajaChozhan2DAnimationShortFilmReleased = React.lazy(() => import("./Pages/NewsEvents/raja-raja-chozhan-2D-animation-short-film-released-2019"));
const InterCollegiateCulturalFestMiraki2K19Chennai = React.lazy(() => import("./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2019-chennai"));
const InterCollegiateCulturalFestMiraki2K19Bangalore = React.lazy(() => import("./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2019-bangalore"));
const TenthConvocationCeremonyOfICAT2019 = React.lazy(() => import("./Pages/NewsEvents/10th-convocation-ceremony-2019-chennai"));
const Icreas2018AtStPatricksAcademy = React.lazy(() => import("./Pages/NewsEvents/icreas-2018-st-patricks-academy"));
const CSRActivityToHelpKeralaFloodVictims = React.lazy(() => import("./Pages/NewsEvents/CSR-activity-help-kerala-flood-victims"));
const EighthGraduateShowcaseICATBangalore = React.lazy(() => import("./Pages/NewsEvents/8th-graduate-showcase-2018-bangalore"));
const EighthStudentShowcase2018ICATHyderabad = React.lazy(() => import("./Pages/NewsEvents/8th-student-showcase-2018-hyderabad"));
const ImageOpensNewCentreAtChannasandraBangalore = React.lazy(() => import("./Pages/NewsEvents/image-opens-new-centre-channasandra-2018-bangalore"));
const InterCollegiateCulturalFestMiraki18 = React.lazy(() => import("./Pages/NewsEvents/inter-collegiate-cultural-fest-miraki-2018-chennai"));
const ICREAS2017ChellammalVidyalaya = React.lazy(() => import("./Pages/NewsEvents/icreas-2017-chellammal-vidyalaya"));
const Icreas2017TheAnnualArtExpo = React.lazy(() => import("./Pages/NewsEvents/icreas-2017-the-annual-art-expo"));
const ImageOpensNewCentreAtNagercoilTamilnadu = React.lazy(() => import("./Pages/NewsEvents/image-opens-new-centre-nagarcoil-2017"));
const Icreas2017MontfortSchool = React.lazy(() => import("./Pages/NewsEvents/icreas-2017-montfort-school"));
const ICATStudentsWonSeveralAwardsAtDelhiCGAwards2016 = React.lazy(() => import("./Pages/NewsEvents/ICAT-students-won-several-awards-delhi-CG-awards-2016"));
const ICATStudentsWonVariousPrizesAtKalaavaibhavaCulturalFest = React.lazy(() => import("./Pages/NewsEvents/ICAT-students-won-various-prizes-at-kalaavaibhava-cultural-fest-2016"));
const InaugurationStJosephsCollege = React.lazy(() => import("./Pages/NewsEvents/inauguration-at-st-joseph-college-2016"));
const ICREAS16StMichealsAcademy = React.lazy(() => import("./Pages/NewsEvents/icreas-2016-st-micheal-academy"));
const LaunchOfCreativeCareerProgam = React.lazy(() => import("./Pages/NewsEvents/launch-creative-career-program-2015-bangalore"));
const ImageAdvancedLearningSystemLaunchMeet = React.lazy(() => import("./Pages/NewsEvents/image-advanced-learning-system-launch-meet"));
const UGPGConvocation2012 = React.lazy(() => import("./Pages/NewsEvents/ug-pg-convocation-2012"));
const SeminarOnVisualEffectsInEnthiran = React.lazy(() => import("./Pages/NewsEvents/seminar-visual-effects-enthiran"));
const GameGrind2012 = React.lazy(() => import("./Pages/NewsEvents/game-grind-2012-chennai"));

const Contact = React.lazy(() => import("./Pages/contact"));
const TermsAndConditions = React.lazy(() => import("./Pages/terms-and-conditions"));

var routes = [
    { path: "/", name: "Home", element: Home },
    { path: "/image-creative-education", name: "Image Creative Education", element: ImageCreativeEducation },
    { path: "/icat", name: "Icat", element: Icat },
    { path: "/imageminds", name: "Imageminds", element: Imageminds },
    { path: "/about-the-Founder-CEO", name: "About The Founder CEO", element: AboutTheFounderCEO },
    { path: "/milestones", name: "Milestones", element: milestones },
    { path: "/news-events", name: "News Events", element: NewsEvents },

    { path: "/launching-creative-technologies-with-gen-ai-chennai-2024", name: "Launching Creative Technologies with Gen AI Chennai 2024", element: LaunchingCreativeTechnologiesWithGenAIChennai2024 },
    { path: "/iac-prize-distribution-ceremony-chennai-2024", name: "Iac Prize Distribution Ceremony Chennai 2024", element: IacPrizeDistributionCeremonyChennai2024 },
    { path: "/photography-contest-winners-runner-prize-distribution", name: "Photography Contest Winners Runner Prize Distribution", element: PhotographyContestWinnersRunnerPrizeDistribution },
    { path: "/comfusionism-miraki-2023-chennai", name: "Comfusionism Miraki 2023 Chennai", element: ComfusionismMiraki2023Chennai },
    { path: "/AVGC-stakeholder-consultation-workshop", name: "AVGC Stakeholder Consultation Workshop", element: AVGCStakeholderConsultationWorkshop },
    { path: "/rampculture-image-fashion-dept-students-participated-avant-garde-fashion-show-2023", name: "Rampculture Image Fashion Dept Students Participated Avant Garde Fashion Show 2023", element: RampcultureImageFashionDeptStudentsParticipatedAvantGardeFashionShow2023 },
    { path: "/inter-collegiate-cultural-fest-miraki-2023-chennai", name: "InterCollegiateCulturalFestMiraki2023", element: InterCollegiateCulturalFestMiraki2023 },
    { path: "/15th-graduate-showcase-2023-chennai", name: "FifteenthGraduationShowcaseOfICATDesignMediaCollegeChennai", element: FifteenthGraduationShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/13th-graduate-showcase-2023-bangalore", name: "ThirteenthGraduationShowcaseOfICATDesignMediaCollegeBangalore", element: ThirteenthGraduationShowcaseOfICATDesignMediaCollegeBangalore },
    { path: "/12th-graduate-showcase-2023-hyderabad", name: "TwelvethGraduationShowcaseOfICATDesignMediaCollegeHyderabad", element: TwelvethGraduationShowcaseOfICATDesignMediaCollegeHyderabad },
    { path: "/14th-graduate-showcase-2022-chennai", name: "FourteenthGraduationShowcaseOfICATDesignMediaCollegeChennai", element: FourteenthGraduationShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/12th-graduate-showcase-2022-bangalore", name: "TwelvethGraduationShowcaseOfICATDesignMediaCollegeBangalore", element: TwelvethGraduationShowcaseOfICATDesignMediaCollegeBangalore },
    { path: "/student-showcase-2021-chennai", name: "StudentShowcaseOfICATDesignMediaCollegeChennai", element: StudentShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/11th-bcu-convocation-ceremony-2020", name: "EleventhBCUConvocation2020", element: EleventhBCUConvocation2020 },
    { path: "/inter-collegiate-cultural-fest-miraki-2020-bangalore", name: "InterCollegiateCulturalFestMiraki2020Bangalore", element: InterCollegiateCulturalFestMiraki2020Bangalore },
    { path: "/inter-collegiate-cultural-fest-miraki-2020-chennai", name: "InterCollegiateCulturalFestMiraki2020Chennai", element: InterCollegiateCulturalFestMiraki2020Chennai },
    { path: "/9th-graduate-showcase-2019-bangalore", name: "NinethGraduationShowcaseOfICATDesignMediaCollegeBangalore", element: NinethGraduationShowcaseOfICATDesignMediaCollegeBangalore },
    { path: "/9th-graduate-showcase-2019-hyderabad", name: "NinethGraduationShowcaseOfICATDesignMediaCollegeHyderabad", element: NinethGraduationShowcaseOfICATDesignMediaCollegeHyderabad },
    { path: "/11th-graduate-showcase-2019-chennai", name: "EleventhGraduationShowcaseOfICATDesignMediaCollegeChennai", element: EleventhGraduationShowcaseOfICATDesignMediaCollegeChennai },
    { path: "/won-most-inclusive-educational-programme-gold-award", name: "IMAGEMINDSWonTheMostInclusiveEducationalProgrammeGoldAward", element: IMAGEMINDSWonTheMostInclusiveEducationalProgrammeGoldAward },
    { path: "/raja-raja-chozhan-2D-animation-short-film-released-2019", name: "RajaRajaChozhan2DAnimationShortFilmReleased", element: RajaRajaChozhan2DAnimationShortFilmReleased },
    { path: "/inter-collegiate-cultural-fest-miraki-2019-chennai", name: "InterCollegiateCulturalFestMiraki2K19Chennai", element: InterCollegiateCulturalFestMiraki2K19Chennai },
    { path: "/inter-collegiate-cultural-fest-miraki-2019-bangalore", name: "InterCollegiateCulturalFestMiraki2K19Bangalore", element: InterCollegiateCulturalFestMiraki2K19Bangalore },
    { path: "/10th-convocation-ceremony-2019-chennai", name: "TenthConvocationCeremonyOfICAT2019", element: TenthConvocationCeremonyOfICAT2019 },
    { path: "/icreas-2018-st-patricks-academy", name: "Icreas2018AtStPatricksAcademy", element: Icreas2018AtStPatricksAcademy },
    { path: "/CSR-activity-help-kerala-flood-victims", name: "CSRActivityToHelpKeralaFloodVictims", element: CSRActivityToHelpKeralaFloodVictims },
    { path: "/8th-graduate-showcase-2018-bangalore", name: "EighthGraduateShowcaseICATBangalore", element: EighthGraduateShowcaseICATBangalore },
    { path: "/8th-student-showcase-2018-hyderabad", name: "EighthStudentShowcase2018ICATHyderabad", element: EighthStudentShowcase2018ICATHyderabad },
    { path: "/image-opens-new-centre-channasandra-2018-bangalore", name: "ImageOpensNewCentreAtChannasandraBangalore", element: ImageOpensNewCentreAtChannasandraBangalore },
    { path: "/inter-collegiate-cultural-fest-miraki-2018-chennai", name: "InterCollegiateCulturalFestMiraki18", element: InterCollegiateCulturalFestMiraki18 },
    { path: "/icreas-2017-chellammal-vidyalaya", name: "ICREAS2017ChellammalVidyalaya", element: ICREAS2017ChellammalVidyalaya },
    { path: "/icreas-2017-the-annual-art-expo", name: "Icreas2017TheAnnualArtExpo", element: Icreas2017TheAnnualArtExpo },
    { path: "/image-opens-new-centre-nagarcoil-2017", name: "ImageOpensNewCentreAtNagercoilTamilnadu", element: ImageOpensNewCentreAtNagercoilTamilnadu },
    { path: "/icreas-2017-montfort-school", name: "Icreas2017MontfortSchool", element: Icreas2017MontfortSchool },
    { path: "/ICAT-students-won-several-awards-delhi-CG-awards-2016", name: "ICATStudentsWonSeveralAwardsAtDelhiCGAwards2016", element: ICATStudentsWonSeveralAwardsAtDelhiCGAwards2016 },
    { path: "/ICAT-students-won-various-prizes-at-kalaavaibhava-cultural-fest-2016", name: "ICATStudentsWonVariousPrizesAtKalaavaibhavaCulturalFest", element: ICATStudentsWonVariousPrizesAtKalaavaibhavaCulturalFest },
    { path: "/inauguration-at-st-joseph-college-2016", name: "InaugurationStJosephsCollege", element: InaugurationStJosephsCollege },
    { path: "/icreas-2016-st-micheal-academy", name: "ICREAS16StMichealsAcademy", element: ICREAS16StMichealsAcademy },
    { path: "/launch-creative-career-program-2015-bangalore", name: "LaunchOfCreativeCareerProgam", element: LaunchOfCreativeCareerProgam },
    { path: "/image-advanced-learning-system-launch-meet", name: "ImageAdvancedLearningSystemLaunchMeet", element: ImageAdvancedLearningSystemLaunchMeet },
    { path: "/ug-pg-convocation-2012", name: "UGPGConvocation2012", element: UGPGConvocation2012 },
    { path: "/seminar-visual-effects-enthiran", name: "SeminarOnVisualEffectsInEnthiran", element: SeminarOnVisualEffectsInEnthiran },
    { path: "/game-grind-2012-chennai", name: "GameGrind2012", element: GameGrind2012 },
    { path: "/contact", name: "Contact", element: Contact },
    { path: "/terms-and-conditions", name: "Terms and Conditions", element: TermsAndConditions },
]

export default routes;